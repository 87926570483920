define("ember-cli-script/services/script", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScriptService extends _service.default {
    /**
     * Dynamically load a script.
     *
     * @param src         URL source for the script.
     * @return {*}
     */
    load(src) {
      const scripts = document.querySelectorAll(`script[src^="${src}"]`);

      if (scripts.length !== 0) {
        return Promise.resolve();
      }

      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);
        const parent = document.head || document.body;

        if (!parent) {
          throw new Error('The application must have a <head> or <body> element.');
        }

        parent.appendChild(script);
      });
    }

  }

  _exports.default = ScriptService;
});