define("ember-l10n/helpers/pt", ["exports", "ember-l10n/helpers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper provides contextual singular message, where context has to
   * be given as 4th argument, otherwise just works the same as `t` helper.
   *
   * ```html
   * {{pt 'This is {{variable}}.' variable=someBoundProperty 'context'}}
   * ```
   *
   * @namespace Helper
   * @class PT
   * @extends Ember.Helper
   * @public
   */
  class PTHelper extends _base.default {
    compute(_ref, hash) {
      let [msgid, msgctxt] = _ref;
      let {
        l10n
      } = this;

      if (!msgid) {
        return msgid;
      }

      return l10n.pt(msgid, msgctxt, hash);
    }

  }

  _exports.default = PTHelper;
});