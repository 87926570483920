define("ember-l10n/components/get-text", ["exports", "@ember/component", "@ember/template-factory", "@ember/utils", "@glimmer/component"], function (_exports, _component, _templateFactory, _utils, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-triple-curlies }}
  {{#each this.messageParts as |part|~}}
    {{~#if part.name}}
      {{~yield part.text part.name}}
    {{~else~}}
      {{~#if @unescapeText~}}
        {{{part.text}}}
      {{~else~}}
        {{~part.text~}}
      {{~/if~}}
    {{~/if~}}
  {{~/each}}
  */
  {
    "id": "4E3l6Rbs",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"messageParts\"]]],null]],null],null,[[[41,[30,1,[\"name\"]],[[[18,3,[[30,1,[\"text\"]],[30,1,[\"name\"]]]]],[]],[[[41,[30,2],[[[2,[30,1,[\"text\"]]]],[]],[[[1,[30,1,[\"text\"]]]],[]]]],[]]]],[1]],null]],[\"part\",\"@unescapeText\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"yield\"]]",
    "moduleName": "ember-l10n/components/get-text.hbs",
    "isStrictMode": false
  });

  /**
   * A simple helper component to include dynamic parts - mostly link-to helper - within gettext message ids.
   *
   * ```html
   * <GetText @message={{t "My translation with {{dynamicLink 'optional link text'}} and {{staticLink}}"}} as |text placeholder|>
   *  {{!-- You can omit the if helper if you have only one dynamic part --}}
   *  {{~#if (eq placeholder 'myLink')}}
   *    {{~#link-to 'my-route'}}
   *      {{~text}} {{!-- will render 'optional link text' so that it's contained in PO file! --}}
   *    {{~/link-to~}}
   *  {{~/if~}}
   *  {{~#if (eq placeholder 'staticLink')}}
   *    <a href="http://www.google.com">Google</a>
   *  {{~/if~}}
   * </GetText>
   * ```
   *
   * @namespace Component
   * @class GetText
   * @extends Ember.Component
   * @public
   */
  class GetTextComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "message", void 0);

      _defineProperty(this, "unescapeText", void 0);
    }

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Collection of all message parts splitted
     * into normal text pieces and all
     * placeholders from message id.
     *
     * @property messageParts
     * @type {Array}
     * @public
     */
    get messageParts() {
      let {
        message
      } = this.args;

      if (!message) {
        // eslint-disable-next-line no-console
        console.error('<GetText>: You have to provide a @message containing a gettext message!');
        return [];
      }

      if (typeof message !== 'string') {
        try {
          message = message.toString();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('<GetText>: @message must be either a string or an object implementing toString() method!');
          return [];
        }
      }

      let parts = [];
      let regex = /{{\s*(\w+)(?:\s*(?:'|")([^'"]*)(?:'|"))?\s*}}/;
      let result;
      let text = message;

      while (result = regex.exec(text)) {
        let split = text.split(result[0]); // 1) normal text

        parts.push({
          isPlaceholder: false,
          text: split[0]
        }); // 2) placeholder

        parts.push({
          isPlaceholder: true,
          name: result[1],
          text: result[2]
        }); // set remainder

        text = split[1];
      } // add last part if any


      if (!(0, _utils.isEmpty)(text)) {
        parts.push({
          isPlaceholder: false,
          text
        });
      }

      return parts;
    }

  }

  _exports.default = GetTextComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GetTextComponent);
});