define("ember-l10n/helpers/t-var", ["exports", "ember-l10n/helpers/t"], function (_exports, _t) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TVarHelper extends _t.default {}

  _exports.default = TVarHelper;
});