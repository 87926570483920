define("ember-l10n/helpers/t", ["exports", "ember-l10n/helpers/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper provides gettext singularization for message ids.
   * It takes singular message id as positional arguments. All
   * placeholders can be provided through named arguments.
   *
   * ```html
   * {{t 'Your current role: {{role}}' role=someBoundProperty}}
   * ```
   *
   * @namespace Helper
   * @class T
   * @extends Ember.Helper
   * @public
   */
  class THelper extends _base.default {
    compute(_ref, hash) {
      let [msgid] = _ref;
      let {
        l10n
      } = this;

      if (!msgid) {
        return msgid;
      }

      return l10n.t(msgid, hash);
    }

  }

  _exports.default = THelper;
});